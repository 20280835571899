define("discourse/plugins/discourse-oauth2-basic/discourse/templates/connectors/login-before-modal-body/resend-confirm-login-modal", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.siteSettings.oauth2_enabled}}
    <div class="login-left-side" style="width: calc(100% - 6rem);">
      <div class="login-welcome-header">
          <h1 class="login-title">{{i18n "login.header_title"}}</h1>
          <img src={{this.wavingHandURL}} alt="" class="waving-hand">
      </div>
      <div id="not-activated">
          <p>{{html-safe (i18n "login.not_activated" sentTo=this.email)}}</p>
          {{d-button action=(action "sendActivationEmail") label="login.resend_title" icon="envelope" class="btn-primary resend resend-activation-button"}}
      </div>
      <div id="sent-activation" class="hidden">
          <p>{{html-safe (i18n "login.sent_activation_email_again" currentEmail=this.email)}}</p>
      </div>
   </div>
  {{/if}}
  */
  {
    "id": "0OyuFZIz",
    "block": "[[[41,[30,0,[\"siteSettings\",\"oauth2_enabled\"]],[[[1,\"  \"],[10,0],[14,0,\"login-left-side\"],[14,5,\"width: calc(100% - 6rem);\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"login-welcome-header\"],[12],[1,\"\\n        \"],[10,\"h1\"],[14,0,\"login-title\"],[12],[1,[28,[35,1],[\"login.header_title\"],null]],[13],[1,\"\\n        \"],[10,\"img\"],[15,\"src\",[30,0,[\"wavingHandURL\"]]],[14,\"alt\",\"\"],[14,0,\"waving-hand\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,1,\"not-activated\"],[12],[1,\"\\n        \"],[10,2],[12],[1,[28,[35,2],[[28,[37,1],[\"login.not_activated\"],[[\"sentTo\"],[[30,0,[\"email\"]]]]]],null]],[13],[1,\"\\n        \"],[1,[28,[35,3],null,[[\"action\",\"label\",\"icon\",\"class\"],[[28,[37,4],[[30,0],\"sendActivationEmail\"],null],\"login.resend_title\",\"envelope\",\"btn-primary resend resend-activation-button\"]]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,1,\"sent-activation\"],[14,0,\"hidden\"],[12],[1,\"\\n        \"],[10,2],[12],[1,[28,[35,2],[[28,[37,1],[\"login.sent_activation_email_again\"],[[\"currentEmail\"],[[30,0,[\"email\"]]]]]],null]],[13],[1,\"\\n    \"],[13],[1,\"\\n \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"i18n\",\"html-safe\",\"d-button\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-oauth2-basic/discourse/templates/connectors/login-before-modal-body/resend-confirm-login-modal.hbs",
    "isStrictMode": false
  });
});