define("discourse/plugins/discourse-oauth2-basic/discourse/connectors/login-before-modal-body/resend-confirm-login-modal", ["exports", "@ember/component", "@ember/object", "discourse/lib/user-activation", "discourse/lib/waving-hand-url"], function (_exports, _component, _object, _userActivation, _wavingHandUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ResendConfirmLoginModal extends _component.default {
    email = null;
    username = null;
    renderOk = false;
    getCookieValue(name) {
      const regex = new RegExp(`(^| )${name}=([^;]+)`);
      const match = document.cookie.match(regex);
      if (match) {
        return match[2];
      }
    }
    get wavingHandURL() {
      return (0, _wavingHandUrl.wavingHandURL)();
    }
    init() {
      super.init(...arguments);
      const e = this.getCookieValue("_oa2e");
      this.set('email', window.atob(e.replace('%0A', '')));
      const u = this.getCookieValue("_oa2u");
      this.set('username', window.atob(u.replace('%0A', '')));
    }
    didRender() {
      super.didRender(...arguments);
      const modalBodyLeft = document.querySelectorAll('.login-modal-body .login-left-side')[1];
      modalBodyLeft.className += ' hidden';
      const modalBodyRight = document.querySelector('.login-modal-body .login-right-side');
      modalBodyRight.className += ' hidden';
      const modalAlert = document.getElementById('modal-alert');
      modalAlert.className += ' hidden';
    }
    sendActivationEmail() {
      const resendButton = document.querySelector('.resend-activation-button');
      resendButton.disabled = true;
      (0, _userActivation.resendActivationEmail)(this.get('username')).then(() => {
        const notActivated = document.getElementById('not-activated');
        notActivated.className += ' hidden';
        const sentActivation = document.getElementById('sent-activation');
        sentActivation.className = '';
      });
    }
    static #_ = (() => dt7948.n(this.prototype, "sendActivationEmail", [_object.action]))();
  }
  _exports.default = ResendConfirmLoginModal;
});